
/* Jotto container title */
.jotto-layout-title {
	padding: 10px;
    margin: 0px 0px 10px;

	background-color: var(--theme-color);
	color: white;
}

.jotto-layout {
    display: inline-flex;
    justify-content: space-between;
    height: 100%;
    padding: 0 1rem;

    overflow-x: scroll;
}

/* Jotto column */
.jotto-column-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
}

.jotto-column-wrapper.sticky {
    position: sticky;
    top: 0;
    min-width: 14rem;
    overflow-x: hidden;
    overflow-y: auto;
}

.jotto-column-wrapper.sticky::-webkit-scrollbar {
    width: 5px;
}

.jotto-column-wrapper.sticky::-webkit-scrollbar-track {
    background-color: lightgray;
}

.jotto-column-wrapper.sticky::-webkit-scrollbar-thumb {
    background: var(--theme-color-sec);
}

.jotto-column-wrapper.sticky::-webkit-scrollbar-thumb:hover {
    background: var(--theme-color-third);
}

.jotto-column-wrapper .name-with-avatar {
    justify-content: center;
    margin-bottom: 0.5rem;
}

/* Jotto word container */
.jotto-word {
    display: inline-flex;
    justify-content: space-between;
    width: 11rem;
    margin-bottom: 0.25rem;
}

/* Jotto word letter container */
.jotto-word-tile {
    align-items: center;
    justify-content: center;

    display: inline-flex;
    height: 2rem;
    width: 2rem;
    box-sizing: border-box;
    border: 1px solid black;

    font-size: 1.5rem;
    line-height: 1;
}

/* Jotto guess */
.jotto-guess-display {
    width: 13rem;
}

.jotto-guess {
    width: 13rem;
}

.jotto-guess-score {
    margin-left: 0.5rem;

    font-size: 1.5rem;
    color: #555;
}

.jotto-guess-score.low {color: #ff0000;} /* 1 & 2 */
.jotto-guess-score.medium {color: #ff9900;} /* 3 */
.jotto-guess-score.high {color: #ffcc00;} /* 4 */
.jotto-guess-score.max {color: #8fbb00;} /* 5 */
.jotto-guess-score.winner {color: #00ff00;} /* 6 */

/* Jotto answer */
.jotto-answer-display {
    width: 13rem;
    margin-bottom: 1rem;
}

.jotto-answer-display .action-name {
    display: none;
}

/* Jotto answer word slightly bigger */
.jotto-answer-display .jotto-word {
    width: 13rem;
}

.jotto-answer-display .jotto-word-tile {
    height: 2.4rem;
    width: 2.4rem;

    color: lightcoral;
    font-weight: bold;
}

/* Jotto input container */
.jotto-input-container {
    position: relative;
    height: 2.4rem;
    width: 13rem;
}

.jotto-input-container .jotto-word {
    width: 13rem;
}

.jotto-input-container .jotto-word-tile {
    height: 2.4rem;
    width: 2.4rem;

    font-weight: bold;
}

/* Jotto text input */
.jotto-input {
    /* Positioning */
    position: absolute;
    left: 0;

    /* Display & Box Model */
    width: inherit;
    height: inherit;
    box-sizing: content-box;
    padding: 0;
    margin: 0;
    border-width: 0;

    /* Other */
    opacity: 0;
    color: transparent;
    background-color: transparent;
    user-select: none;
    transition: cubic-bezier(0.165, 0.84, 0.44, 1) 0.1s;
}

/* highlight input */
.jotto-input:focus {
    border-width: 0px 0px 4px 0px;
    border-color: red;

    opacity: 0.5;
    transition: cubic-bezier(0.165, 0.84, 0.44, 1) 0.1s;
}

/* Cheat sheet */
.jotto-cheatsheet {
    justify-content: center;
    flex-direction: column;
    display: flex;
}

.btn.jotto-cheatsheet-reset {
    margin: auto;
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 6px 12px;
}

.jotto-cheatsheet-row {
    justify-content: center;
    flex-direction: row;
    display: flex;
}

.jotto-cheatsheet-letter {
    align-items: center;
    justify-content: center;

    display: inline-flex;
    height: 1.8rem;
    width: 1.8rem;
    box-sizing: border-box;
    border: 1px solid black;
    margin: 2px;

    font-size: 1.2rem;
    line-height: 1;
}

.jotto-cheatsheet-letter.none {background-color: unset;}
.jotto-cheatsheet-letter.maybe {background-color: #ffcc00;}
.jotto-cheatsheet-letter.yes {background-color: #00a000;}
.jotto-cheatsheet-letter.no {background-color: #ff4a4a;}
