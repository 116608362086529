.roles-loading {
    height: 200px !important;
}

.role-list-container {
    display: flex;
    flex-flow: column;
}

.role-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    min-height: 50px;
    padding: 0px 0px 10px 10px;

    background-color: var(--scheme-color);
}

.role-cell {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    margin: 10px 10px 0px 0px;
    padding: 5px;

    background-color: var(--scheme-color-sec);
    color: var(--scheme-color-text);
    box-shadow: 0px 0px 1px black;
}

.role-list .role-count-wrap {
    margin-right: 3px;

    color: var(--scheme-color-text);
}

.role-cell-content {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    cursor: pointer;
}

.modifier {
    width: 16px;
    height: 16px;

    background-image: url("/images/modifiers.png");
}

.role-count-wrap {
    display: flex;
    flex-flow: row nowrap;

    color: var(--scheme-color-text);
}

.role .modifier {
    position: absolute;
    top: -5px;
    right: -5px;
}

.role {
    position: relative;

    width: 30px;
    height: 40px;
    border-radius: 3px;
    background-image: url("/images/roles.png");

    -webkit-filter: drop-shadow(0px 0px 0.35px gray) drop-shadow(0px 0px 0.35px gray) drop-shadow(0px 0px 0.35px gray) drop-shadow(0px 0px 0.35px gray);

    filter: drop-shadow(0px 0px 0.35px gray) drop-shadow(0px 0px 0.35px gray) drop-shadow(0px 0px 0.35px gray) drop-shadow(0px 0px 0.35px gray);
}

.role.bg {
    background-color: #484848;
}

.role.small {
    width: 30px;
    height: 30px;
    border-radius: 50px;

    background-image: url("/images/roles.png");
}

.role-null {
    background-position: 0 -40px;

    -webkit-filter: none;
    filter: none;
}

/* 
* Roles w/o icons 
*/

.role-Silhouette {
    background-position: 0 0;
}

/***********
*
* Mafia
*
***********/

/* 
* Village 
*/

.role-Mafia-Villager {
    background-position: -30px 0;
}

.role-Mafia-Doctor {
    background-position: -60px 0;
}

.role-Mafia-Cop {
    background-position: -90px 0;
}

.role-Mafia-Gunsmith {
    background-position: -120px 0;
}

.role-Mafia-Oracle {
    background-position: -150px 0;
}

.role-Mafia-Bomb {
    background-position: -180px 0;
}

.role-Mafia-Sheriff {
    background-position: -210px 0;
}

.role-Mafia-Deputy {
    background-position: -240px 0;
}

.role-Mafia-Vigilante {
    background-position: -270px 0;
}

.role-Mafia-Hunter {
    background-position: -780px -120px;
}

.role-Mafia-Seeker {
    background-position: -30px -40px;
}

.role-Mafia-Miller {
    background-position: -120px -40px;
}

.role-Mafia-Tracker {
    background-position: -150px -40px;
}

.role-Mafia-Watcher {
    background-position: -180px -40px;
}

.role-Mafia-Detective {
    background-position: -210px -40px;
}

.role-Mafia-Granny {
    background-position: -240px -40px;
}

.role-Mafia-Templar {
    background-position: -270px -40px;
}

.role-Mafia-Blacksmith {
    background-position: -300px -40px;
}

.role-Mafia-Monkey {
    background-position: -30px -80px;
}

.role-Mafia-Village-Idiot {
    background-position: -60px -80px;
}

.role-Mafia-Drunk {
    background-position: -90px -80px;
}

.role-Mafia-Warden {
    background-position: -120px -80px;
}

.role-Mafia-Trapper {
    background-position: -150px -80px;
}

.role-Mafia-Charon {
    background-position: -240px -80px;
}

.role-Mafia-Bulletproof {
    background-position: -270px -80px;
}

.role-Mafia-Medic {
    background-position: -300px -80px;
}

.role-Mafia-Funsmith {
    background-position: 0px -120px;
}

.role-Mafia-Insane-Cop {
    background-position: -180px -80px;
}

.role-Mafia-Naive-Cop {
    background-position: -210px -80px;
}

.role-Mafia-Paranoid-Cop {
    background-position: -240px -80px;
}

.role-Mafia-Celebrity {
    background-position: -60px -40px;
}

.role-Mafia-Mason {
    background-position: -90px -40px;
}

.role-Mafia-Agent {
    background-position: -30px -40px;
}

.role-Mafia-Jailer {
    background-position: 0px -80px;
}

.role-Mafia-Priest {
    background-position: -150px -80px;
}

.role-Mafia-Town-Crier {
    background-position: -30px -120px;
}

.role-Mafia-Capybara {
    background-position: -60px -120px;
}

.role-Mafia-Commuter {
    background-position: -90px -120px;
}

.role-Mafia-Neighbor {
    background-position: -120px -120px;
}

.role-Mafia-Nurse {
    background-position: -150px -120px;
}

.role-Mafia-Caroler {
    background-position: -180px -120px;
}

.role-Mafia-Dreamer {
    background-position: -210px -120px;
}

.role-Mafia-Mistletoe {
    background-position: -240px -120px;
}

.role-Mafia-Judge {
    background-position: -270px -120px;
}

.role-Mafia-Snoop {
    background-position: -300px -120px;
}

.role-Mafia-Party-Host {
    background-position: -930px -0px;
}

.role-Mafia-Cutler {
    background-position: -960px -0px;
}

.role-Mafia-Snowman {
    background-position: -990px -0px;
}

.role-Mafia-Virgin {
    background-position: -930px -40px;
}

.role-Mafia-Mimic {
    background-position: -960px -40px;
}

.role-Mafia-Resurrectionist {
    background-position: -990px -40px;
}

.role-Mafia-Sapling {
    background-position: -930px -80px;
}

.role-Mafia-Baker {
    background-position: -960px -80px;
}

.role-Mafia-Ghost {
    background-position: -990px -80px;
}

.role-Mafia-Lightkeeper {
    background-position: -870px -0px;
}

.role-Mafia-Chef {
    background-position: -900px -0px;
}

.role-Mafia-Cyclist {
    background-position: -930px -120px;
}

.role-Mafia-Justice {
    background-position: -960px -120px;
}

.role-Mafia-Journalist {
    background-position: -990px -120px;
}

.role-Mafia-King {
    background-position: -870px -40px;
}

.role-Mafia-Loudmouth {
    background-position: -900px -40px;
}

.role-Mafia-Enchantress {
    background-position: -900px -80px;
}

.role-Mafia-Messenger {
    background-position: -870px -80px;
}

.role-Mafia-Invisible-Man {
    background-position: -870px -120px;
}

.role-Mafia-Visitor {
    background-position: -900px -120px;
}

.role-Mafia-Sleepwalker {
    background-position: -1020px -0px;
}

.role-Mafia-Governor {
    background-position: -1020px -40px;
}

.role-Mafia-Waitress {
    background-position: -1020px -80px;
}

.role-Mafia-Mortician {
    background-position: -1020px -120px;
}

.role-Mafia-Robin-Hood {
    background-position: -300px 0;
}

.role-Mafia-Tinkerer {
    background-position: -1050px -0px;
}

.role-Mafia-Trickster {
    background-position: -1050px -40px;
}

.role-Mafia-Santa {
    background-position: -1050px -80px;
}

.role-Mafia-Medium {
    background-position: -1050px -120px;
}

.role-Mafia-Mourner {
    background-position: -840px -80px;
}

.role-Mafia-Forager {
    background-position: -840px -120px;
}

.role-Mafia-Samurai {
    background-position: -810px -120px;
}

.role-Mafia-Fabulist {
    background-position: -660px -120px;
}

.role-Mafia-Crafter {
    background-position: -690px -120px;
}

/* 
* Mafia 
*/

.role-Mafia-Mafioso {
    background-position: -330px 0;
}

.role-Mafia-Hooker {
    background-position: -360px 0;
}

.role-Mafia-Inquisitor {
    background-position: -390px 0;
}

.role-Mafia-Godfather {
    background-position: -420px 0;
}

.role-Mafia-Poisoner {
    background-position: -480px 0;
}

.role-Mafia-Deafener {
    background-position: -510px 0;
}

.role-Mafia-Blackmailer {
    background-position: -540px 0;
}

.role-Mafia-Stalker {
    background-position: -570px 0;
}

.role-Mafia-Killer-Bee {
    background-position: -600px 0;
}

.role-Mafia-Driver {
    background-position: -330px -40px;
}

.role-Mafia-Lawyer {
    background-position: -360px -40px;
}

.role-Mafia-Spy {
    background-position: -390px 0;
}

.role-Mafia-Sniper {
    background-position: -390px -40px;
}

.role-Mafia-Janitor {
    background-position: -450px -40px;
}

.role-Mafia-Disguiser {
    background-position: -420px -40px;
}

.role-Mafia-Lookout {
    background-position: -510px 0px;
}

.role-Mafia-Gramps {
    background-position: -540px 0px;
}

.role-Mafia-Associate {
    background-position: -480px -40px;
}

.role-Mafia-Scout {
    background-position: -510px -40px;
}

.role-Mafia-Arsonist {
    background-position: -540px -40px;
}

.role-Mafia-Heartbreaker {
    background-position: -570px -40px;
}

.role-Mafia-Prosecutor {
    background-position: -600px -40px;
}

.role-Mafia-Tailor {
    background-position: -330px -80px;
}

.role-Mafia-Actress {
    background-position: -360px -80px;
}

.role-Mafia-Fabricator {
    background-position: -390px -80px;
}

.role-Mafia-Yakuza {
    background-position: -420px -80px;
}

.role-Mafia-Necromancer {
    background-position: -450px -80px;
}

.role-Mafia-Ninja {
    background-position: -480px -80px;
}

.role-Mafia-Graverobber {
    background-position: -510px -80px;
}

.role-Mafia-Clown {
    background-position: -540px -80px;
}

.role-Mafia-Diabolist {
    background-position: -570px -80px;
}

.role-Mafia-Illusionist {
    background-position: -600px -80px;
}

.role-Mafia-Cat-Lady {
    background-position: -330px -120px;
}

.role-Mafia-Slasher {
    background-position: -360px -120px;
}

.role-Mafia-Jinx {
    background-position: -390px -120px;
}

.role-Mafia-Informant {
    background-position: -420px -120px;
}

.role-Mafia-Mummy {
    background-position: -450px -120px;
}

.role-Mafia-Medusa {
    background-position: -480px -120px;
}

.role-Mafia-Trespasser {
    background-position: -510px -120px;
}

.role-Mafia-Thief {
    background-position: -570px -120px;
}

.role-Mafia-Courier {
    background-position: -600px -120px;
}

.role-Mafia-Poltergeist {
    background-position: -810px -40px;
}

.role-Mafia-Housekeeper {
    background-position: -840px -40px;
}

.role-Mafia-Framer {
    background-position: -780px -80px;
}

.role-Mafia-Hitman {
    background-position: -810px -80px;
}

.role-Mafia-Interrogator {
    background-position: -630px -120px;
}

/* 
* Independent 
*/

.role-Mafia-Fool {
    background-position: -630px 0;
}

.role-Mafia-Serial-Killer {
    background-position: -660px 0;
}

.role-Mafia-Amnesiac {
    background-position: -720px 0;
}

.role-Mafia-Cultist {
    background-position: -690px 0;
}

.role-Mafia-Survivor {
    background-position: -750px 0;
}

.role-Mafia-Alien {
    background-position: -630px -40px;
}

.role-Mafia-Executioner {
    background-position: -660px -40px;
}

.role-Mafia-Old-Maid {
    background-position: -690px -40px;
}

.role-Mafia-Lover {
    background-position: -720px -40px;
}

.role-Mafia-Traitor {
    background-position: -750px -40px;
}

.role-Mafia-Vengeful-Spirit {
    background-position: -630px -80px;
}

.role-Mafia-Mastermind {
    background-position: -660px -80px;
}

.role-Mafia-Prophet {
    background-position: -690px -80px;
}

.role-Mafia-Clockmaker {
    background-position: -720px -80px;
}

.role-Mafia-Phantom {
    background-position: -750px -80px;
}

.role-Mafia-Autocrat {
    background-position: -750px -120px;
}

.role-Mafia-Turkey {
    background-position: -720px -120px;
}

/* 
* Monsters 
*/

.role-Mafia-Lycan {
    background-position: -780px 0;
}

.role-Mafia-Witch {
    background-position: -810px 0;
}

.role-Mafia-Cthulhu {
    background-position: -840px 0;
}

.role-Mafia-The-Thing {
    background-position: -780px -40px;
}

/* 
* Modifiers
*/

.modifier-Mafia-Armored {
    background-position: -96px -16px;
}

.modifier-Mafia-Explosive {
    background-position: -16px 0;
}

.modifier-Mafia-Armed {
    background-position: -32px 0;
}

.modifier-Mafia-Exposed {
    background-position: -48px 0;
}

.modifier-Mafia-Chameleon {
    background-position: -64px 0;
}

.modifier-Mafia-Humble {
    background-position: -80px 0;
}

.modifier-Mafia-Lone {
    background-position: -96px 0;
}

.modifier-Mafia-Solitary {
    background-position: -96px 0;
}

.modifier-Mafia-Delayed {
    background-position: -112px 0;
}

.modifier-Mafia-Even {
    background-position: -128px 0;
}

.modifier-Mafia-Odd {
    background-position: -144px 0;
}

.modifier-Mafia-One-Shot {
    background-position: 0 -16px;
}

.modifier-Mafia-Bloodthirsty {
    background-position: -16px -16px;
}

.modifier-Mafia-Loud {
    background-position: -32px -16px;
}

.modifier-Mafia-Scatterbrained {
    background-position: -48px -16px;
}

.modifier-Mafia-Astral {
    background-position: -64px -16px;
}

.modifier-Mafia-Unblockable {
    background-position: -80px -16px;
}

/* for role prediction */
.modifier-Mafia-Unknown {
    background-position: -144px -32px;
}


/***********
*
* Split Decision
*
***********/

/*
* Blue
*/

.role-Split-Decision-Blue-Member {
    background-position: 0 -160px;
}

.role-Split-Decision-President {
    background-position: -30px -160px;
}

/*
* Red
*/

.role-Split-Decision-Red-Member {
    background-position: -60px -160px;
}

.role-Split-Decision-Bomber {
    background-position: -90px -160px;
}

/*
* Independent
*/

.role-Split-Decision-Gambler {
    background-position: -120px -160px;
}



/***********
*
* Resistance
*
***********/

/*
* Resistance
*/

.role-Resistance-Rebel {
    background-position: 0 -240px;
}

.role-Resistance-Merlin {
    background-position: -30px -240px;
}

.role-Resistance-Percival {
    background-position: -60px -240px;
}

/*
* Spies
*/

.role-Resistance-Spy {
    background-position: -330px -240px;
}

.role-Resistance-Morgana {
    background-position: -300px -240px;
}

.role-Resistance-Oberon {
    background-position: -270px -240px;
}

/***********
*
* One Night
*
***********/

/*
* Village
*/

.role-One-Night-Villager {
    background-position: 0 -320px;
}

.role-One-Night-Hunter {
    background-position: -30px -320px;
}

.role-One-Night-Mason {
    background-position: -60px -320px;
}

.role-One-Night-Seer {
    background-position: -90px -320px;
}

.role-One-Night-Robber {
    background-position: -120px -320px;
}

.role-One-Night-Troublemaker {
    background-position: -150px -320px;
}

.role-One-Night-Insomniac {
    background-position: -180px -320px;
}

/*
* Werewolves
*/

.role-One-Night-Werewolf {
    background-position: -330px -320px;
}

.role-One-Night-Minion {
    background-position: -360px -320px;
}

/*
* Independent
*/

.role-One-Night-Tanner {
    background-position: -630px -320px;
}

.role-One-Night-Drunk {
    background-position: -660px -320px;
}

.role-One-Night-Doppelganger {
    background-position: -690px -320px;
}


/***********
*
* Jotto
*
***********/

.role-Jotto-Jotter {
    background-position: -720px -240px;
}