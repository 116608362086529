.chat-wrapper {
	display: flex;
	flex-flow: column;
	align-items: stretch;
	justify-content: flex-start;

	position: fixed !important;
	bottom: 0px;
	right: 50px;
	z-index: 30;

	box-shadow: 0px 0px 15px black;
}

.chat-wrapper .top-bar {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;

	min-width: 150px;
	padding: 10px 20px;

	background-color: var(--theme-color);
	cursor: pointer;
}

.chat-wrapper .top-bar i {
	margin-right: 10px;
}

.chat-wrapper .window {
	display: flex;
	flex-flow: row nowrap;
	align-items: stretch;
	justify-content: flex-start;

	width: 700px;
	height: 500px;
}

.chat-wrapper .left-panel {
	display: flex;
	flex-flow: column;
	align-items: stretch;
	justify-content: flex-start;

	width: 200px;
	border-right: 1px solid var(--scheme-color-border);
	background-color: var(--scheme-color-sec);
}

.chat-wrapper .channel-type-nav {
	align-self: center;

	display: flex;
	flex-flow: row nowrap;
	align-items: stretch;
	justify-content: center;

	margin-top: 10px;
	border: 1px solid var(--theme-color-sec);
}

.chat-wrapper .channel-type-nav .channel-type {
	padding: 5px;

	background-color: var(--theme-color);
	cursor: pointer;
}

.chat-wrapper .channel-type-nav .channel-type:hover {
	opacity: 0.95;
}

.chat-wrapper .channel-type-nav .channel-type.sel {
	background-color: var(--theme-color-sec);
}

.chat-wrapper .user-search {
	margin: 10px 10px 0px 10px;
	padding: 5px;
	border: 1px solid var(--scheme-color-border);

	background-color: var(--scheme-color);
	color: var(--scheme-color-text);
}

.chat-wrapper .channel-list {
	flex-grow: 1;
	flex-basis: 0px;

	display: flex;
	flex-flow: column;
	align-items: stretch;
	justify-content: flex-start;

	padding: 10px;

	overflow-y: auto;
}

.chat-wrapper .channel {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;

	margin-bottom: 5px;
	padding: 5px 8px;
	border: 1px solid var(--theme-color);
	line-height: 18px;

	background-color: var(--theme-color);
	cursor: pointer;
}

.chat-wrapper .channel:hover {
	opacity: 0.95;
}

.chat-wrapper .channel.sel {
	background-color: var(--theme-color-sec);
	border-color: var(--theme-color-sec);
}

.chat-wrapper .channel .name-with-avatar {
	overflow-x: hidden;
	cursor: pointer !important;
}

.chat-wrapper .channel .name-with-avatar .user-name {
	max-width: 110px;

	color: white !important;
}

.create-dm-btn {
	align-self: center;

	margin-top: 10px;
	margin-bottom: 10px;
	padding: 8px !important;
}

.create-dm-btn i {
	margin-right: 10px;
}

.chat-wrapper .right-panel {
	flex-grow: 1;

	display: flex;
	flex-flow: column;
	align-items: stretch;
	justify-content: flex-start;

	width: 0px;

	background-color: var(--scheme-color);
	color: black;
}

.chat-wrapper .channel-top {
	display: flex;
	flex-flow: row nowrap;
	align-items: stretch;
	justify-content: flex-start;

	box-sizing: border-box;
	border-bottom: 1px solid var(--scheme-color-border);

	background-color: var(--scheme-color-sec);
}

.chat-wrapper .channel-top .channel-name {
	flex-grow: 1;
	align-self: stretch;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;

	box-sizing: border-box;
	width: 0px;
	min-width: 0%;
	padding: 10px;

	overflow-x: auto;
	font-weight: 700;
	color: var(--scheme-color-text);
}

.chat-wrapper .channel-top .name-with-avatar {
	margin-right: 10px;
}

.chat-wrapper .channel-top .close-dm {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;

	padding: 0px 15px;
	border-left: 1px solid #c7c7c7;

	cursor: pointer;
	color: var(--scheme-color-text);
}

.chat-wrapper .channel-messages {
	flex-grow: 1;
	flex-basis: 0px;

	display: flex;
	flex-flow: column;
	align-items: stretch;
	justify-content: flex-start;

	padding: 0px 10px;

	overflow-y: auto;
}

.chat-wrapper .message {
	flex-shrink: 0;

	display: flex;
	flex-flow: column;
	align-items: flex-start;
	justify-content: flex-start;

	position: relative;
	margin: 15px 50px 0px 0px;
}

.chat-wrapper .message.self {
	align-items: flex-end;

	margin: 15px 0px 0px 50px;
}

.chat-wrapper .message:last-child {
	padding-bottom: 30px;
}

.chat-wrapper .message .info {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;

}

.chat-wrapper .message .info .timestamp {
	margin-left: 10px;

	opacity: 0.5;
	font-size: 13px;
	color: var(--scheme-color-text);
}

.chat-wrapper .message .content {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: flex-start;

	box-sizing: border-box;
	max-width: 100%;
	margin-top: 5px;
	padding: 5px;

	background-color: var(--scheme-color-sec);
	overflow-x: hidden;
	white-space: pre-wrap;
}

.chat-wrapper .message.self .content {
	background-color: var(--scheme-color-sec);
	color: var(--scheme-color-text);
}

.chat-wrapper .message .content a {
	color: #598dff;
}

.chat-wrapper .message .context {
	position: absolute;
	padding: 5px 0px;

	background-color: var(--theme-color);
	color: white;
	visibility: hidden;
	z-index: 20;
}

.chat-wrapper .message .context .item {
	padding: 5px 10px;

	cursor: pointer;
}

.chat-wrapper .message .context .item:hover {
	opacity: 0.8;
}

.chat-wrapper .channel-input-wrapper {
	display: flex;
	flex-flow: row nowrap;
	align-items: stretch;
	justify-content: flex-start;

	padding: 10px;
	border-top: 1px solid var(--scheme-color-border);

	background-color: var(--scheme-color-sec);
}

.chat-wrapper .channel-input {
	flex-grow: 1;

	border: 1px solid var(--scheme-color-border);
	padding: 5px;

	background-color: var(--scheme-color);
	color: var(--scheme-color-text);
}

/* Scrollbar */

.chat-wrapper ::-webkit-scrollbar {
	width: 10px;
	height: 5px;
	z-index: -1;
}

.chat-wrapper ::-webkit-scrollbar-track {
	background: transparent;
	z-index: -1;
}

.chat-wrapper ::-webkit-scrollbar-thumb {
	background: var(--scroll-color);
	z-index: -1;
}

.chat-wrapper ::-webkit-scrollbar-thumb:hover {
	background: var(--scroll-color);
	opacity: 0.8;
	z-index: -1;
}



/*
 * Media Queries
 */

@media (max-width: 800px) {
	.chat-wrapper {
		right: 0px;
	}

	.chat-wrapper .top-bar {
		min-width: 0px;
		padding: 10px 15px;
	}

	.chat-wrapper .top-bar .label {
		display: none;
	}

	.chat-wrapper .top-bar i {
		display: block;
		margin: 0px;

		font-size: 22px;
	}

	.chat-wrapper .window {
		box-sizing: border-box;
		width: 99vw;
		height: 70vh;

		overflow-x: scroll;
		scroll-snap-type: x mandatory;
	}

	.chat-wrapper .left-panel {
		flex-shrink: 0;

		scroll-snap-align: start;
		scroll-snap-stop: always;
	}

	.chat-wrapper .right-panel {
		flex-shrink: 0;

		box-sizing: border-box;
		width: 95vw;

		scroll-snap-align: end;
		scroll-snap-stop: always;
	}
}