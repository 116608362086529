.user-search-page {
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	justify-content: flex-start;
}

.user-search-page .main {
	flex-grow: 1;

	display: flex;
	flex-flow: column;
	align-items: flex-start;
	justify-content: flex-start;

	min-height: 100px;
}

.user-search-page input {
	border-radius: 0px !important;
}

.user-search-page .users {
	display: flex;
	flex-flow: row wrap;
	align-items: stretch;
	justify-content: flex-start;
}

.user-search-page .user-cell {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;

	margin: 10px 10px 0px 0px;
	padding: 10px;

	background-color: var(--scheme-color);
	color: var(--scheme-color-text);
}

.user-search-page .user-cell .status-icon {
	margin-left: 5px;
}

.user-search-page .user-lists {
	display: flex;
	flex-flow: column;
	align-items: stretch;
	justify-content: flex-start;
}

.user-search-page .newest-users,
.user-search-page .flagged-users {
	flex-shrink: 0;

	margin-left: 10px;
	width: 250px;

	background-color: var(--theme-color);
	color: white;
}

.user-search-page .users-list {
	display: flex;
	flex-flow: column;
	align-items: flex-start;
	justify-content: flex-start;

	padding: 5px;

	background-color: var(--scheme-color);
}

.user-search-page .users-list .page-nav {
	margin: 0px;
}

.user-search-page .users-list .page-nav:first-child {
	margin-bottom: 10px;
}

.user-search-page .users-list .page-nav:last-child {
	margin-top: 10px;
}

.user-search-page .joined {
	margin-left: 45px;

	color: var(--scheme-color-text);
	opacity: 0.6;
}

.user-search-page .flagged-users {
	margin-top: 10px;
}