.play .item-list {
    min-height: 300px;

    background-color: var(--scheme-color);
    color: var(--scheme-color-text);

}

.play .top-bar {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    font-family: PoppinsExtraBold;
}

.play .top-link {
    width: 120px;
    margin-right: 30px;
    padding: 3px 5px;

    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
}

.play .top-link.active {
    background-color: var(--theme-color-sec);
}

.row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--scheme-color);
    padding: 8px;
}
.row.odd {
    background-color: var(--scheme-color-sec);
}

.row .btn {
    width: 120px;
    margin-right: 10px;
    padding: 3px 5px;

    font-family: Autophobia;
    font-size: 18px;
}


.refresh-games {
    margin-left: 10px;
    font-size: 20px;
    cursor: pointer;
}

.refresh-games:hover {
    color: #d9d9d9;
}

.refresh-games:active {
    color: #a09e9e;
}

.page-nav {
    margin-top: 10px;
}

.btn-group-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

@media (max-width: 800px) {
    .play .top-bar {
        flex-flow: row wrap;
    }

    .play .top-link {
        width: auto;
        margin-right: 3px !important;
        font-size: 15px !important;
    }
}