.alert-list {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column-reverse;

    position: fixed;
    top: 10px;
    left: 0px;

    width: 100%;

    opacity: 0.9;
    z-index: 50;
}

.alert {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    width: 50vw;
    max-width: fit-content;

    margin-bottom: 10px;
    padding: 5px;
    margin-left: 55px;
    padding-left: 24px;
    padding-right: 24px;

    background-color: var(--theme-color);
    color: white;
    box-shadow: 0px 0px 5px black;

    -webkit-transition: opacity 500ms linear;
    transition: opacity 500ms linear;
}

.hide-alert {
    margin-right: 10px;

    cursor: pointer;
}

.hide-alert:hover {
    color: var(--scheme-color-sec);
}

.alert-error {
    background-color: #cf6679;
    color: #121212;
}

.alert-success {
    background-color: #9ccc65;
    color: #121212;
}

.alert-error .hide-alert:hover,
.alert-success .hide-alert:hover {
    color: #262626;
}

.alert a {
    text-decoration: none;
    color: var(--theme-color-text);
    cursor: pointer;
}

@media (max-width: 1600px) {
    .alert {
        width: 60vw;
        margin-left: 0;
    }
}

@media (max-width: 1400px) {
    .alert {
        width: 70vw;
    }
}

@media (max-width: 1200px) {
    .alert {
        width: 80vw;
    }
}

@media (max-width: 1000px) {
    .alert {
        width: 90vw;
    }
}